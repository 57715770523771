* {
  box-sizing: border-box;
}

@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto/Roboto-Regular.ttf);
}

@font-face {
  font-family: STIX_Two_Math;
  src: url(../fonts/STIX_Two_Math/STIXTwoMath-Regular.ttf);
}

:root {
  --slate: #262626;
  --white: #fcfcfc;
  --blue: #20967f;
  --salmon: #bf6274;
  --dark: var(--slate);
  --light: var(--white);
  --primary: var(--blue);
  --dark-accent: var(--slate);
  --accent: var(--salmon);
  --accent-forest: var(--slate);
  --nav-height: 105px;
}

body {
  background: var(--white);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ul,
li,
a,
span,
div {
  font-family: "Roboto", sans-serif;
}

h2 {
  font-size: 2.5rem;
  color: var(--dark);
}

h3 {
  font-size: 1.75rem;
  color: var(--dark);
}

h4 {
  font-size: 1.25rem;
  color: var(--dark);
}

p {
  font-size: 1rem;
  color: var(--dark);
}

a {
  text-decoration: none;
}

nav {
  width: 100%;
  display: grid;
  grid: auto/ repeat(2, 1fr);
  padding: 10px 0;
}

nav div {
  height: 100%;
  width: 100%;
}

nav .nav-1 {
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 30px;
}

nav .nav-2 {
  padding-right: 30px;
}

nav .nav-1 .logo_img {
  width: 25%;
}

nav div ul {
  min-height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
}
nav div ul li {
  text-align: center;
  margin: 0 10px;
}

nav div ul li a {
  color: var(--primary);
}

nav div ul li a:hover {
  color: var(--dark);
}

section.full-section {
  height: 1080px;
}

section.half-section {
  height: 540px;
}

section.page-title {
  min-height: calc(100vh - var(--nav-height));
  display: grid;
  grid: auto / repeat(2, 1fr);
  align-items: center;
  justify-content: center;
  background-image: url("../img/header.jpeg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

section.page-title .title {
  width: 55%;
  margin-left: 20%;
}

section.page-title h2 {
  font-size: 3rem;
  color: var(--dark);
}

section.product-grid {
  display: grid;
  grid: auto / repeat(5, auto);
  grid-gap: 20px;
  margin: 20px;
}

section.product-grid div:nth-child(4) {
  grid-column: 4 / -1;
  padding: 0 40px;
}

section.product-grid div {
  background: var(--primary);
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
}

section.product-grid div div {
  display: flex;
  align-items: flex-start;
}

.product-img {
  padding: 10px;
  width: 100%;
}

section.text-img {
  display: grid;
  grid: 600px / repeat(2, 1fr);
  align-items: center;
}

section.text-img div .text-img-text {
  background: var(--accent);
  padding: 40px 60px 60px 40px;
  width: 55%;
  margin-left: 20%;
  border-radius: 4px;
}

footer.card-section {
  background: var(--slate);
  display: grid;
  grid: 400px / repeat(3, 1fr);
  align-items: center;
}

footer.card-section div {
  display: flex;
  justify-content: center;
}

.card {
  flex-direction: column;
  border: solid 1px var(--dark-accent);
  background: var(--dark-accent);
  width: 80%;
  padding: 30px;
  border-radius: 4px;
}

#product-1,
#product-2,
#product-3 {
  position: relative;
}

#overlay-1,
#overlay-2,
#overlay-3 {
  background-color: #333333aa;
  opacity: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition-duration: 300ms;
}

#overlay-1 h4,
#overlay-2 h4,
#overlay-3 h4 {
  color: #fff;
}

.btn {
  padding: 10px;
  text-decoration: none;
  border-radius: 4px;
  transition: 200ms;
}

.btn.btn-large{
  padding:2rem;
}

.btn.btn-navigation {
  background: var(--teal-blue);
  color: var(--white);
}

.btn:hover {
  filter: brightness(0.9);
}

.btn:active {
  filter: brightness(1);
}

section.tours-section {
  background: var(--primary);
  display: grid;
  grid: 1fr / repeat(3, 1fr);
}

section.tours-section div {
  position: relative;
  justify-content: center;
  border: solid 1px black;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

section.tours-section div div.overlay {
  display: grid;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #333333dd;
  opacity: 0;
  transition-duration: 300ms;
}
section.tours-section div div.overlay a {
  font-size: 2rem;
  color: var(--light);
}
section.tours-section div div.overlay:hover {
  opacity: 1;
}

section.tours-section div.home-tours {
  background-image: url("../img/HomeTours.jpeg");
}

section.tours-section div.business-tours {
  background-image: url("../img/Business-Tours.jpeg");
}
section.tours-section div.showroom {
  background-image: url("../img/showroom.jpeg");
}

section.call-to-action-section {
  height: 10vh;
  background: var(--light);
  display: grid;
  grid: 1fr/ 1fr 1fr;
}
section.call-to-action-section .call {
  display: grid;
  justify-content: center;
  align-items: center;
}
section.call-to-action-section .action {
  display: grid;
  justify-content: center;
  align-items: center;
  background: red;
}

section.four-square {
  display: grid;
  grid: 1fr / repeat(2, 1fr);
}

section.four-square div {
  background: black;
  border: solid 1px white;
}
